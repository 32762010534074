<template>
  <div id="schedule-create">
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col><h2 style="color: white; margin-bottom: 1em">Schedule - Create</h2></b-col>
        <b-col></b-col>
      </b-row>
      <b-row>
        <b-col>
          <SideMenu></SideMenu>
        </b-col>
        <b-col class="col-sm-9">
          <div v-if="loading" class="text-center">
            <b-spinner variant="secondary" label="Loading"></b-spinner>
          </div>
          <div v-else class="col-sm-9" style="color: white; text-align: right">
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Employee:"
            >
              <b-form-select
                      v-model="employee['name']"
                      :options="employees"
                      value-field="name"
                      text-field="name"
              ></b-form-select>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Date:"
                    label-for="date"
            >
              <b-form-input id="date" type="date" v-model="workSchedule.date"></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Start time:"
                    label-for="start-time"
            >
              <b-form-timepicker
                      id="start-time"
                      placeholder="Choose a start time"
                      v-model="workSchedule.start_time"
                      reset-button
                      now-button
              ></b-form-timepicker>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="End time:"
                    label-for="end-time"
            >
              <b-form-timepicker
                      id="end-time"
                      placeholder="Choose an end time"
                      v-model="workSchedule.end_time"
                      reset-button
                      now-button
              ></b-form-timepicker>
            </b-form-group>
            <router-link class="link-color" :to="{ name: 'CmsSchedule' }" @click.prevent>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </router-link>
            <b-button  @click.prevent="saveSchedule()" type="submit" variant="success">Submit</b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import SideMenu from '@/components/SideMenu.vue'
  import axios from "axios";

  export default {
    data() {
      return {
        loading: true,
        workSchedule: [],
        employee: [],
        employees: []
      }
    },
    components: {
      SideMenu
    },
    beforeCreate () {
      this.axios
        .get('https://batavia-backend.herokuapp.com/api/user')
        .then(response => {
          this.employees = response.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    methods: {
      saveSchedule() {
        this.axios.post(
            'https://batavia-backend.herokuapp.com/api/work-schedule',
            {
              user_id: this.getEmployeeId(),
              date: this.workSchedule.date,
              start_time: this.workSchedule.start_time,
              end_time: this.workSchedule.end_time,
              hours: this.getHours(),
              completed: 0
            },
            {
              headers: {
                Authorization: axios.defaults.headers.common.Authorization
              }
            }
        )
        .then(() => {
          this.$router.push({ name: 'CmsSchedule' })
        })
      },

      getEmployeeId() {
        let employeeName = this.employee['name']
        let employeeId = this.employee['id']

        this.employees.map(function (employee) {
          if (employee['name'] === employeeName) {
            employeeId = employee['id'];
          }
        })

        return employeeId
      },

      getHours() {
        let startTime = new Date(this.workSchedule.date + ' ' + this.workSchedule.start_time)
        let endTime = new Date(this.workSchedule.date + ' ' + this.workSchedule.end_time)

        let milliseconds = endTime - startTime
        let minutes = Math.floor(milliseconds / 60000)
        let hours = Math.floor(minutes / 60);

        return parseFloat(hours + '.' + minutes.toString().slice(-2))
      }
    }
  }
</script>